<template>
  <div>
      <navbar title="دعوت از دوستان" route='/apps'/>
      <v-main>
        <v-card class="pa-3 card flat-card" color="background">
          <v-row>
            <v-col id="inviteFriend" cols="12" md="7" class="d-flex align-center">
              <!-- <span class="font font-weight-bold secondaryText--text">کد دعوت شما</span>
              <span class="mx-2"></span> -->
              <v-text-field label="کد دعوت شما" readonly hide-details outlined dense :value="invite.invite"/>
            </v-col>
          </v-row>
          <!-- <v-row align="center">
            <v-col cols="12" md="7">
              <div :class="!showAll ? 'line-break' : 'show-more'">
              <span class="medium-font">
              لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در
              </span>
              </div>
              <div class="d-flex justify-center">
                <v-btn v-if="!showAll" @click="showAll = !showAll" class="button secondaryText--text mt-2" small text>مشاهده متن کامل</v-btn>
                <v-btn v-if="showAll" @click="showAll = !showAll" class="button secondaryText--text mt-2" small text>بستن</v-btn>
              </div>
            </v-col>
            <v-col cols="12" md="3" class="d-flex justify-center pt-0">
              <v-btn @click="inviteText = !inviteText" class="primary button" small>
                <v-icon size="20" class="mx-1">mdi-content-copy</v-icon>
                کپی متن پیشنهادی دعوت از دوستان
              </v-btn>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col>
              <!-- <invitedFriends color="background"/> -->
              <v-card class="pa-3 card flat-card">
                <v-row class="mb-2">
                  <v-col>
                    <v-icon size="23">mdi-account-plus</v-icon>
                    <span class="mx-1"></span>
                    <span class="medium-font font-weight-bold">دوستانی که شما دعوت کرده اید</span>
                  </v-col>
                </v-row>
                <div v-if="invite_list != 'nothing'">
                <v-row v-for="list in invite_list" :key="list.Customer_ID">
                  <v-col>
                    <v-avatar size="30">
                      <v-img :src="list.Photo_Path"/>
                    </v-avatar>
                    <span class="mx-2"></span>
                    <span class="medium-font" v-text="list.Nick_Name"></span>
                  </v-col>
                </v-row>
                </div>
                <v-row v-if="invite_list == 'nothing'">
                  <v-col class="d-flex flex-column align-center justify-center">
                    <v-avatar tile size="70">
                      <v-img src="@/assets/icon/chat.svg"/>
                    </v-avatar>
                    <span class="medium-font ">کسی با کد دعوت شما ثبت نام نکرده است</span>
                  </v-col>
                </v-row>
                <!-- <v-divider class="my-2 mx-3" /> -->
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-main>
      <div class="overlay" v-if="inviteText" @click.self="inviteText = !inviteText">
        <inviteDialog v-if="inviteText"/>
      </div>
  </div>
</template>
<style lang="scss" scoped>
.line-break{
  white-space: initial;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
.show-more{
  display: flex;
}
</style>
<script>
import invitedFriends from './components/accordions/invitedFriendsAccordion'
import navbar from '@/components/navbar/appBar'
import inviteDialog from './components/cards/inviteFriendDialog'
import { getProfile, getinviteList } from '@/views/UserProfile/models/user'
import { mapState } from 'vuex'
export default {
  components: {
    // invitedFriends,
    navbar,
    inviteDialog
  },
  data: () => ({
    showAll: false,
    inviteText: false
  }),
  computed:{
    ...mapState({
      invite: state => state.user.user,
      invite_list: state => state.user.invite_list
    })
  },
  created () {
    getProfile()
    getinviteList()
  }
}
</script>